  
  .displayCartItems {
    width: 700px;
    height: 250px;
    display: flex;
  
    align-items: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    margin: 30px;
  }
  
  .displayCartItems img {
    width: 200px;
  }
  
  .displayCartItems .description {
    width: 30%;
    font-size: 20px;
  }
  
  .addToCartBttn {
    background-color: transparent;
    border: 2px solid rgb(19, 19, 19);
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
  }
  
  .addToCartBttn:hover {
    background-color: rgb(19, 19, 19);
    color: white;
    cursor: pointer;
  }
  
  