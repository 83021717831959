.nav {
  width: 100%;
  height: 80px;
  background-color: rgb(19, 19, 19);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navLinks {
  margin-right: 50px;
  display: flex;
  align-items: center;
}

.navLinks a {
  text-decoration: none;
  color: white;
  margin-left: 20px;
  font-size: 25px;
}

.nav .btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
}

.nav .btn:hover {
  background-color: #45a049;
}
